<template>
  <div class="card">
    <div class="card-header">
      <DatePicker @onChange="onTabChange" @onDateChange="onDateChange" />
      <span>- 订单趋势分析</span>
    </div>
    <div class="card-content">
      <ve-line
        :data="chartData"
        :extend="extend"
        :settings="settings"
      ></ve-line>
    </div>
  </div>
</template>

<script>
import * as orderStatistics from '@/api/orderStatistics'
import DatePicker from '../common/datePicker'
import { getDateRange, fmtDataRange } from '../common/helper'

export default {
  components: {
    DatePicker
  },
  data () {
    return {
      settings: {
        labelMap: {
          date: '日期',
          orderCount: '订单数',
          goodsCount: '订单件数',
          orderAmount: '订单金额',
          sellFee: '订单利润'
        }
      },
      chartData: {
        columns: ['date', 'orderCount', 'goodsCount', 'orderAmount', 'sellFee'],
        rows: []
      },
      extend: {
        legend: {
          show: false
        }
      }
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData (date = 30) {
      const res = await orderStatistics.queryByDay(getDateRange(date))
      this.handleData(res)
    },
    onTabChange (val) {
      this.fetchData(val)
    },
    async onDateChange (data) {
      const res = await orderStatistics.queryByDay(fmtDataRange(data))
      this.handleData(res)
    },
    handleData (data) {
      this.chartData.rows = data.map(o => {
        o.orderAmount = this._pennyToYuan(o.orderAmount)
        o.sellFee = this._pennyToYuan(o.sellFee)
        return o
      })
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  background-color: #ffffff;
  height: 550px;
  margin: 20px auto;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    border-bottom: 1px solid #d9e0f0;

    span {
      color: #999999;
      margin-right: 20px;
    }
  }

  &-content {
    width: 100%;
    /* display: grid; */
    /* grid-template-columns: 3fr 3fr 4fr; */
  }

  &-chart {
    &-title {
      margin-top: -20px;
      height: 25px;
      font-size: 18px;
      font-weight: 500;
      color: #7d7e8e;
      line-height: 25px;
    }
    border-right: 1px solid #d9e0f0;
  }

  &-table {
    max-height: 440px;
    padding: 20px;
    overflow: auto;
  }
}

.tooltip {
  display: flex;
  align-items: center;
  margin-right: 30px;
  span {
    margin-left: 5px;
  }
}
</style>
