<template>
  <div class="card">
    <div class="card-header">
      <DatePicker @onChange="onTabChange" @onDateChange="onDateChange" />
      <span>- 下单用户地域信息</span>
    </div>
    <el-table :data="allData">
      <el-table-column prop="provinceName" label="省份"> </el-table-column>
      <el-table-column prop="cityName" label="城市"> </el-table-column>
      <el-table-column prop="userCount" label="下单用户数"> </el-table-column>
    </el-table>
  </div>
</template>

<script>
import * as orderStatistics from '@/api/orderStatistics'
import DatePicker from '../common/datePicker'
import { getDateRange, fmtDataRange } from '../common/helper'

export default {
  components: {
    DatePicker
  },
  data () {
    return {
      allData: [],
      settings: {},
      chartData: {
        columns: ['date', 'memberCount', 'newMemberCount'],
        rows: []
      },
      extend: {
        legend: {
          show: false
        }
      }
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData (date = 30) {
      const res = await orderStatistics.queryByUser(getDateRange(date))
      this.handleData(res)
    },
    onTabChange (val) {
      this.fetchData(val)
    },
    async onDateChange (data) {
      const res = await orderStatistics.queryByUser(fmtDataRange(data))
      this.handleData(res)
    },
    handleData (data) {
      this.chartData.rows = data
      this.allData = data
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  background-color: #ffffff;
  height: 550px;
  margin: 20px auto;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    border-bottom: 1px solid #d9e0f0;

    span {
      color: #999999;
      margin-right: 20px;
    }
  }

  &-content {
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 1fr;
  }

  &-chart {
    &-title {
      margin-top: -20px;
      height: 25px;
      font-size: 18px;
      font-weight: 500;
      color: #7d7e8e;
      line-height: 25px;
    }
    border-right: 1px solid #d9e0f0;
  }

  &-table {
    max-height: 440px;
    padding: 20px;
    overflow: auto;
    &-title {
      text-align: left;
      height: 25px;
      font-size: 18px;
      font-weight: 500;
      color: #7d7e8e;
      line-height: 25px;
      &:before {
        content: "";
        background: #1d90fbff;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 8px;
        margin-bottom: 5px;
      }
    }
  }
}

.tooltip {
  display: flex;
  align-items: center;
  margin-right: 30px;
  span {
    margin-left: 5px;
  }
}
</style>
