<template>
  <div>
    <div class="container">
      <div class="container-header">
        <DatePicker @onChange="onTabChange" @onDateChange="onDateChange" />
        <span></span>
      </div>
      <div class="summary container-content">
        <div class="summary-item">
          <div class="summary-item-title">订单数</div>
          <div class="summary-item-number">{{ summary.orderCount }}</div>
        </div>
        <div class="summary-item">
          <div class="summary-item-title">订单金额</div>
          <div class="summary-item-number">{{ _pennyToYuan(summary.orderAmount) }}</div>
        </div>
        <div class="summary-item">
          <div class="summary-item-title">订单利润</div>
          <div class="summary-item-number">{{ _pennyToYuan(summary.sellFee) }}</div>
        </div>
        <div class="summary-item">
          <div class="summary-item-title">订单件数</div>
          <div class="summary-item-number">{{ summary.goodsCount }}</div>
        </div>
        <div class="summary-item" style="border: none;">
          <div class="summary-item-title">客单价</div>
          <div class="summary-item-number">{{ _pennyToYuan(summary.price) }}</div>
        </div>
      </div>
    </div>

    <DayChart />

    <UserChart />
  </div>
</template>

<script>
import * as orderStatistics from '@/api/orderStatistics'
import DayChart from './day'
import UserChart from './user'
import DatePicker from '../common/datePicker'
import { getDateRange, fmtDataRange } from '../common/helper'

export default {
  components: {
    DatePicker,
    DayChart,
    UserChart
  },
  data () {
    return {
      summary: {
        orderCount: 0,
        orderAmount: 0,
        goodsCount: 0,
        price: 0
      }
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData (date = 30) {
      const summary = await orderStatistics.summary(getDateRange(date))
      this.summary = summary
    },
    onTabChange (val) {
      this.fetchData(val)
    },
    async onDateChange (data) {
      const summary = await orderStatistics.summary(fmtDataRange(data))
      this.summary = summary
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  background-color: #ffffff;
  margin: 20px auto;
  &-header {
    display: grid;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    border-bottom: 1px solid #d9e0f0;

    span {
      color: #999999;
    }
  }

  &-content {
    width: 100%;
    /* display: grid; */
    /* grid-template-columns: 3fr 3fr 4fr; */
  }
}

.summary {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  background-color: #ffffff;
  height: 140px;
  &-item {
    height: 100%;
    display: grid;
    align-content: center;
    text-align: center;
    border-right: 1px solid #d9e0f0;
    &-title {
      font-size: 16px;
      color: #7d7e8e;
    }
    &-number {
      font-size: 32px;
      font-weight: bold;
      color: #505162;
      line-height: 42px;
    }
  }
}
</style>
